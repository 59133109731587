@font-face {
    font-family: 'SF Distant Galaxy';
    src: local('SF Distant Galaxy'), local('SFDistantGalaxy'),
        url('SFDistantGalaxy.woff2') format('woff2'),
        url('SFDistantGalaxy.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

